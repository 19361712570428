import React from "react";
import { Route, Routes } from "react-router-dom";
import GamePage from "../components/GamePage/GamePage";
import TeamListPage from "../components/TeamListPage/TeamListPage";
import TeamPage from "../components/TeamPage/TeamPage";
import Players from "../components/PlayersPage/PlayersPage";
import StandingPage from "../components/StandingPage/StandingPage";

function AppRoutes() 
{
  return (
    <>
      <Routes>
        <Route path="/" element={<GamePage />} />
        <Route path="/Games" element={<GamePage />} />
        <Route path="/Standings" element={<StandingPage />} />
        <Route path="/Teams" element={<TeamListPage />} />
        <Route path="/Team" element={<TeamPage />} />
        <Route path="/players" element={<Players />} />
        <Route path="/players/season/:seasonId" element={<Players />} />
      </Routes>
    </>
  )
}

export default AppRoutes;