import { useContext, useState } from "react";
import { IDivisionStanding } from "../types/IDivisionStanding";
import { standingService } from "../services/StandingService";
import { DataContext } from "../contexts/DataContext";

export interface UseStandingData
{
  standings: IDivisionStanding | undefined,
  refreshStandings(seasonId: string, divisionId: string): Promise<IDivisionStanding | undefined>
}

const useStandings = (): UseStandingData =>
{
  const data = useContext(DataContext);

  const [standings, setStandings] = useState<IDivisionStanding>();

  const refreshStandings = async (seasonId: string, divisionId: string): Promise<IDivisionStanding | undefined> =>
  {
    const league = await data.league.refreshLeague();

    const standingData = await standingService.getStandings(league.id, seasonId, divisionId, true);

    setStandings(standingData);

    return standingData;
  }


  return {
    standings,
    refreshStandings
  }
}

export default useStandings;