import { IDivisionStanding } from "../types/IDivisionStanding";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class StandingService
{
  private standings: IDivisionStanding | undefined;

  getStandings = async (leagueId: string, seasonId: string, divisionId: string, forceRefresh?: boolean): Promise<IDivisionStanding | undefined> =>
  {
    if (!this.standings || forceRefresh)
    {
      if (!leagueId || !seasonId || !divisionId)
      {
        this.standings = undefined;

        return;
      }

      const apiPath = `league/${leagueId}/season/${seasonId}/division/${divisionId}/standing`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IDivisionStanding[]

      this.standings = records[0];
    }

    return this.standings;
  }
}

export const standingService = new StandingService();