import { useContext, useState } from "react";
import { divisionService } from "../services/DivisionService";
import IDivision from "../types/IDivision";
import { DataContext } from "../contexts/DataContext";

export interface UseDivisionsData
{
  divisions: IDivision[],
  getDivision(divisionId: string): IDivision | undefined,
  getDivisionText(divisionId: string): string,
  getFilteredDivisions(seasonId: string): IDivision[],
  refreshDivisions(seasonId: string): Promise<IDivision[]>
}

const useDivisions = (): UseDivisionsData =>
{
  const data = useContext(DataContext);

  const [divisions, setDivisions] = useState<IDivision[]>([]);

  const getDivision = (divisionId: string) => divisions.find(d => d.id === divisionId);

  const getDivisionText = (divisionId: string) => getDivision(divisionId)?.name ?? "Unknown";

  const refreshDivisions = async (seasonId: string): Promise<IDivision[]> =>
  {
    if (!seasonId)
    {
      return [];
    }

    const league = await data.league.refreshLeague();

    const divisionData = await divisionService.getDivisions(league.id, seasonId, true);

    setDivisions(divisionData);

    return divisionData;
  }

  const getFilteredDivisions = (seasonId: string): IDivision[] =>
  {
    return divisions.filter((division) =>
    {
      return division.seasonId === seasonId;
    })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return {
    divisions,
    getDivision,
    getDivisionText,
    getFilteredDivisions,
    refreshDivisions
  }
}

export default useDivisions;