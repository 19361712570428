import { Link } from "react-router-dom";

import RequestStatus from "../../types/RequestStatus";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import { ICompetitorStat } from "../../types/IDivisionStanding";
import TeamLogo from "../TeamLogo/TeamLogo";
import ICompetitor from "../../types/ICompetitor";

interface IStandingTableProps
{
  getCompetitor(competitorId: string): ICompetitor,
  getCompetitorText(competitorId: string): string,
  standings: ICompetitorStat[],
  requestStatus?: RequestStatus,
  singleTeam?: boolean
}

const StandingTable = (props: IStandingTableProps) => 
{
  if (props.requestStatus === RequestStatus.LOADING) 
  {
    return <LoaderAnimation />
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableHeadRow>
            {!props.singleTeam && <>
              <TableHeadCell />
              <TableHeadCell />
              <TableHeadCell>Team</TableHeadCell>
            </>}
            <TableHeadCell>Wins</TableHeadCell>
            <TableHeadCell>Losses</TableHeadCell>
            <TableHeadCell>Ties</TableHeadCell>
            <TableHeadCell>Games</TableHeadCell>
            <TableHeadCell>Points</TableHeadCell>
            <TableHeadCell>Win%</TableHeadCell>
            <TableHeadCell>GF</TableHeadCell>
            <TableHeadCell>GA</TableHeadCell>
            <TableHeadCell>GD</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {props.standings.map((standing: ICompetitorStat, index: number) => (
            <TableDataRow key={standing.competitorId}>
              {!props.singleTeam && <>
                <TableDataCell>{index + 1}</TableDataCell>
                <TableDataCell>
                  <TeamLogo competitor={props.getCompetitor(standing.competitorId)} />
                </TableDataCell>
                <TableDataCell>
                  <Link to={`/Team?teamId=${standing.competitorId}`} style={{ textDecoration: 'none', color: "black" }}>{props.getCompetitorText(standing.competitorId)}</Link>
                </TableDataCell>
              </>}
              <TableDataCell>{standing.wins}</TableDataCell>
              <TableDataCell>{standing.losses}</TableDataCell>
              <TableDataCell>{standing.ties}</TableDataCell>
              <TableDataCell>{standing.gamesPlayed}</TableDataCell>
              <TableDataCell><b>{standing.points}</b></TableDataCell>
              <TableDataCell>{standing.winPercent ? standing.winPercent.toFixed(3) : 0}</TableDataCell>
              <TableDataCell>{standing.scoreFor}</TableDataCell>
              <TableDataCell>{standing.scoreAgainst}</TableDataCell>
              <TableDataCell>{standing.scoreDifference}</TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default StandingTable;