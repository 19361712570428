import React from "react";

import styles from "./TeamCard.module.css"
import ICompetitor from "../../types/ICompetitor";
import TeamLogo, { LOGOSIZE } from "../TeamLogo/TeamLogo";

interface ICompetitorCardProps
{
  competitor: ICompetitor
}

const TeamCard = (props: ICompetitorCardProps) => 
{
  return (
    <div className={styles.card}>
      <TeamLogo competitor={props.competitor} size={LOGOSIZE.MEDIUM} />
      <p>{props.competitor.name}</p>
    </div>
  )
}

export default TeamCard