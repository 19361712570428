import ISeason from "../types/ISeason";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class SeasonService
{
  private seasons: ISeason[] = [];

  getSeasons = async (leagueId: string, forceRefresh?: boolean): Promise<ISeason[]> =>
  {
    if (this.seasons.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season`;

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as ISeason[]

      this.seasons = records;
    }

    return this.seasons
  }

  getSeason = async (leagueId: string, seasonId: string): Promise<ISeason | null> =>
  {
    const targetSeason = (await this.getSeasons(leagueId))
      .find(s => s.id === seasonId);

    return targetSeason ?? null;
  }
}

export const seasonService = new SeasonService();