import React from "react";
import Helmet from "react-helmet";

function PageHelmet() 
{
  return (
    <Helmet>
      <style>
        {`
          html, body {
              height: 100%;
              font-family: "Roboto","Helvetica","Arial",sans-serif
          }

          body {
              //background-image: url("hockey-bg.jpeg");
              background-color: gray;
            }
        `}
      </style>
    </Helmet>
  )
}

export default PageHelmet