import React from 'react';
import Footer from './components/Footer/Footer';
import PageHelmet from './components/PageHelmet/PageHelmet';
import AppRoutes from './routes/AppRoutes';
import Header from './components/Header/Header';

function App() 
{
  return (
    <>
      <PageHelmet />
      <Header />
      <AppRoutes />
      <Footer />
    </>
  );
}

export default App;
