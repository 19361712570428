import IDivision from "../types/IDivision";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class DivisionService
{
  private divisions: IDivision[] = [];

  getDivisions = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<IDivision[]> =>
  {
    if (this.divisions.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season/${seasonId}/division`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IDivision[]

      this.divisions = records;
    }

    return this.divisions;
  }

  getDivision = async (leagueId: string, seasonId: string, divisionId: string): Promise<IDivision | null> =>
  {
    const targetDivision = (await this.getDivisions(leagueId, seasonId))
      .find(d => d.id === divisionId)

    return targetDivision ?? null;
  }
}

export const divisionService = new DivisionService();