import React from "react"
import IRecordType from "../../types/IRecordType"

import styles from "./Selector.module.css"

interface ISelectorProps
{
  label: string,
  currentValue?: string,
  values: IRecordType[],
  handleChange: any,
  allowAllSelection?: boolean
}

const Selector = (props: ISelectorProps) => 
{
  const selectorValue: string = props.currentValue ? props.currentValue : props.label

  return (
    <>
      <select
        className={styles.selectorButton}
        value={selectorValue}
        onChange={props.handleChange}
      >
        {props.allowAllSelection && <option key="all" value="">All {props.label}</option>}

        {props.values.map((s) => 
        {
          return <option
            key={s.id}
            value={s.id}
          >
            {s.name}
          </option>
        })}
      </select>
    </>
  )
}

export default Selector;
