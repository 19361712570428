import IPool from "../types/IPool";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class PoolService
{
  private pools: IPool[] = [];

  getPools = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<IPool[]> =>
  {
    if (this.pools.length === 0 || forceRefresh)
    {
      if (!leagueId || !seasonId)
      {
        return [];
      }

      const apiPath = `league/${leagueId}/season/${seasonId}/pool`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IPool[]

      this.pools = records;
    }

    return this.pools;
  }

  getPool = async (leagueId: string, seasonId: string, poolId: string): Promise<IPool | null> =>
  {
    const targetPool = (await this.getPools(leagueId, seasonId))
      .find(p => p.id === poolId)

    return targetPool ?? null;
  }
}

export const poolService = new PoolService();