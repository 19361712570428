import { useContext, useState } from "react";
import { locationService } from "../services/LocationService";
import ILocation from "../types/ILocation";
import { DataContext } from "../contexts/DataContext";

export interface UseLocationsData
{
  locations: ILocation[],
  getLocation(locationId: string): ILocation | undefined,
  getLocationText(locationId: string): string,
  refreshLocations(): Promise<void>
}

const useLocations = (): UseLocationsData =>
{
  const data = useContext(DataContext);

  const [locations, setLocations] = useState<ILocation[]>([]);

  const getLocation = (locationId: string) => locations.find(l => l.id === locationId);

  const getLocationText = (locationId: string) => getLocation(locationId)?.name ?? "Unknown";

  const refreshLocations = async () =>
  {
    const league = await data.league.refreshLeague();

    const locationsData = await locationService.getLocations(league.id, true);

    setLocations(locationsData);
  }

  return {
    locations,
    getLocation,
    getLocationText,
    refreshLocations
  }
}

export default useLocations;