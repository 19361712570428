import { useContext, useState } from "react";
import IPool from "../types/IPool";
import { poolService } from "../services/PoolService";
import { DataContext } from "../contexts/DataContext";

export interface UsePoolsData
{
  pools: IPool[],
  getPool(poolId: string): IPool | undefined,
  getPoolText(poolId: string): string,
  refreshPools(seasonId: string): Promise<IPool[]>
}

const usePools = (): UsePoolsData =>
{
  const data = useContext(DataContext);

  const [pools, setPools] = useState<IPool[]>([]);

  const getPool = (poolId: string) => pools.find(p => p.id === poolId);

  const getPoolText = (poolId: string) => 
  {
    if (!poolId) return "";

    return getPool(poolId)?.name ?? "Unknown"
  }

  const refreshPools = async (seasonId: string): Promise<IPool[]> =>
  {
    const league = await data.league.refreshLeague();

    const poolData = await poolService.getPools(league.id, seasonId, true);

    setPools(poolData);

    return poolData;
  }

  return {
    pools,
    getPool,
    getPoolText,
    refreshPools
  }
}

export default usePools;