import { useContext, useState } from "react";
import IPlayer from "../types/IPlayer";
import { playerService } from "../services/PlayerService";
import { DataContext } from "../contexts/DataContext";

export interface UsePlayersData
{
  players: IPlayer[],
  getPlayer(playerId: string): IPlayer | undefined,
  getPlayerText(playerId: string): string,
  refreshPlayers(): Promise<IPlayer[]>
}

const usePlayers = (): UsePlayersData =>
{
  const data = useContext(DataContext);

  const [players, setPlayers] = useState<IPlayer[]>([]);

  const getPlayer = (playerId: string) => players.find(p => p.id === playerId);

  const getPlayerText = (playerId: string) => 
  {
    const player = getPlayer(playerId);

    return player
      ? `${player.firstName} ${player.surname}`
      : "Unknown"
  }

  const refreshPlayers = async () =>
  {
    const league = await data.league.refreshLeague();

    const playersData = await playerService.getPlayers(league.id, true);

    setPlayers(playersData);

    return playersData;
  }

  return {
    players,
    getPlayer,
    getPlayerText,
    refreshPlayers
  }
}

export default usePlayers;