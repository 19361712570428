export enum CompetitorType
{
  ID = "ID",
  TEXT = "TEXT"
}

export interface ICompetitorReference
{
  type: CompetitorType
}

export interface ICompetitorIdReference extends ICompetitorReference
{
  type: CompetitorType.ID,
  id: string
}

export interface ICompetitorTextReference extends ICompetitorReference
{
  type: CompetitorType.TEXT,
  text: string
}