import { useContext, useEffect, useState } from "react";
import { DataContext, IDataContext } from "../../contexts/DataContext";
import IDivision from "../../types/IDivision";
import ISeason from "../../types/ISeason";
import ICompetitor from "../../types/ICompetitor";
import RequestStatus from "../../types/RequestStatus";
import useSeasons from "../../hooks/useSeasons";
import useCompetitors from "../../hooks/useCompetitors";
import useDivisions from "../../hooks/useDivisions";

export interface IUseTeamListPage
{
    setSeasonFilter(seasonId: string): void,
    getFilteredCompetitors(divisionId: string): ICompetitor[],
    seasons: ISeason[],
    divisions: IDivision[],
    currentSeason: string | undefined,
    competitors: ICompetitor[],
    requestStatus: RequestStatus,
    handleSeasonSelect(event: React.ChangeEvent<HTMLInputElement>): void
}

function useTeamListPage(): IUseTeamListPage
{
    const data: IDataContext = useContext(DataContext);

    const seasonData = useSeasons();
    const competitorData = useCompetitors();
    const divisionData = useDivisions();

    const [currentSeason, setCurrentSeason] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);

    //#region Set filters

    const setSeasonFilter = (seasonId: string): void =>
    {
        setCurrentSeason(seasonId);
    }

    //#endregion

    //#region Handlers

    const handleSeasonSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setSeasonFilter(event?.target.value);
    };

    //#endregion

    //#region Effects

    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                if (!currentSeason)
                {
                    setRequestStatus(RequestStatus.SUCCESS);

                    return
                }

                await divisionData.refreshDivisions(currentSeason);
                await competitorData.refreshCompetitors(currentSeason);

                setRequestStatus(RequestStatus.SUCCESS);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, [currentSeason]);

    useEffect(() =>
    {
        const getData = async () =>
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                const league = await data.league.refreshLeague();

                await seasonData.refreshSeasons();

                let season = currentSeason;

                if (!season)
                {
                    season = league.defaultSeasonId;

                    setCurrentSeason(season);
                }
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, [])

    //#endregion

    return {
        setSeasonFilter,
        getFilteredCompetitors: (divisionId: string) => competitorData.getFilteredCompetitors(divisionId),
        seasons: seasonData.seasons,
        divisions: divisionData.divisions,
        requestStatus,
        currentSeason,
        competitors: competitorData.competitors,
        handleSeasonSelect
    }
}

export default useTeamListPage;