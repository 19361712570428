import { useContext, useRef, useState } from "react";
import { seasonService } from "../services/SeasonService";
import ISeason from "../types/ISeason";
import { DataContext } from "../contexts/DataContext";

export interface UseSeasonsData
{
  defaultSeason: string,
  seasons: ISeason[],
  getSeason(seasonId: string): ISeason | undefined
  refreshSeasons(): Promise<ISeason[]>,
}

const useSeasons = (): UseSeasonsData =>
{
  const data = useContext(DataContext);

  const [seasons, setSeasons] = useState<ISeason[]>([]);

  const defaultSeason = useRef("");

  const getSeason = (seasonId: string) => seasons.find(s => s.id === seasonId);

  const refreshSeasons = async (): Promise<ISeason[]> =>
  {
    const league = await data.league.refreshLeague();

    const seasonData = await seasonService.getSeasons(league.id, true);

    setSeasons(seasonData);

    defaultSeason.current = league.defaultSeasonId;

    return seasonData;
  }

  return {
    defaultSeason: defaultSeason.current,
    seasons,
    getSeason,
    refreshSeasons,
  }
}

export default useSeasons;