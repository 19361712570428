import React from "react";

import styles from "./TableComponents.module.css"

interface ITableProps {
  children?: React.ReactNode
}

export const Table = (props: ITableProps) => 
{
  return (
    <div className={styles.table}>
      <table cellPadding={0} cellSpacing={0}>
        {props.children}
      </table>
    </div>
  )
}

export const TableHead = (props: ITableProps) => 
{
  return (
    <thead className={styles.tableHeadCell}>
      {props.children}
    </thead>
  )
}

export const TableHeadRow = (props: ITableProps) => 
{
  return (
    <tr>
      {props.children}
    </tr>
  )
}

export const TableHeadCell = (props: ITableProps) => 
{
  return (
    <th className={styles.tableHeadCell}>
      {props.children}
    </th>
  )
}

export const TableBody = (props: ITableProps) => 
{
  return (
    <tbody>
      {props.children}
    </tbody>
  )
}

export const TableDataRow = (props: ITableProps) => 
{
  return (
    <tr className={styles.tableDataRow}>
      {props.children}
    </tr>
  )
}

export const TableDataCell = (props: ITableProps) => 
{
  return (
    <td className={styles.tableDataCell}>
      {props.children}
    </td>
  )
}
