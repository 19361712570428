import IGameList from "../../types/IGame";
import { GameState } from "../../types/GameState";
import getTimeString from "../../utils/timeUtils";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import TeamLogo from "../TeamLogo/TeamLogo";
import { CompetitorType, ICompetitorReference } from "../../types/ICompetitorReference";
import ICompetitor from "../../types/ICompetitor";

interface GamePageProps
{
  games: IGameList[],
  loading: boolean,
  getCompetitorByReference(competitorReference: ICompetitorReference): ICompetitor,
  getCompetitorText(competitor: ICompetitorReference): string,
  getDivisionText(divisionId: string): string,
  getLocationText(locationId: string): string,
  getPoolText(poolId: string): string
}

const getTeamLogo = (
  competitorReference: ICompetitorReference,
  getCompetitor: (competitorReference: ICompetitorReference) => ICompetitor) =>
{
  switch (competitorReference.type)
  {
    case CompetitorType.ID:
      return (
        <TeamLogo competitor={getCompetitor(competitorReference)} />
      )

    case CompetitorType.TEXT:
      return (<></>);
  }
}

const GameTable = (props: GamePageProps) => 
{
  if (props.loading) 
  {
    return <LoaderAnimation />
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell />
            <TableHeadCell>Home</TableHeadCell>
            <TableHeadCell />
            <TableHeadCell>Away</TableHeadCell>
            <TableHeadCell>Score</TableHeadCell>
            <TableHeadCell>Time</TableHeadCell>
            <TableHeadCell>Division</TableHeadCell>
            <TableHeadCell>Pool</TableHeadCell>
            <TableHeadCell>Location</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {props.games.map((game: IGameList) => (
            <TableDataRow key={game.id}>
              <TableDataCell>{game.gameId}</TableDataCell>
              <TableDataCell>
                {getTeamLogo(game.competitorOne, props.getCompetitorByReference)}
              </TableDataCell>
              <TableDataCell>
                {props.getCompetitorText(game.competitorOne)}
              </TableDataCell>
              <TableDataCell>
                {getTeamLogo(game.competitorTwo, props.getCompetitorByReference)}
              </TableDataCell>
              <TableDataCell>
                {props.getCompetitorText(game.competitorTwo)}
              </TableDataCell>
              <TableDataCell>
                {game.gameState === GameState.COMPLETE ? `${game.competitorOneScore} - ${game.competitorTwoScore}` : ""}
              </TableDataCell>
              <TableDataCell>{getTimeString(game.time)}</TableDataCell>
              <TableDataCell>{props.getDivisionText(game.divisionId)}</TableDataCell>
              <TableDataCell>{props.getPoolText(game.poolId)}</TableDataCell>
              <TableDataCell>{props.getLocationText(game.locationId)}</TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default GameTable;