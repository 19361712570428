import { Container } from "@mui/material";
import React from "react"
import { useParams } from "react-router-dom";
import usePlayersPage, { UsePlayersPageData } from "./usePlayersPage";
import { Grid, LoadingOverlay, Select, Table, Text } from "@mantine/core";
import { IGoalieStats, IPlayerStats, PlayerStatsType } from "../../types/IPlayerStats";
import RequestStatus from "../../types/RequestStatus";

const PlayersPage: React.FC = () =>
{
    const { seasonId } = useParams();

    const playersPageData: UsePlayersPageData = usePlayersPage({ seasonId });

    const playerData = playersPageData
        .playerStats
        .filter(p => p.type === PlayerStatsType.PLAYER)
        .sort((a, b) => (b as IPlayerStats).points - (a as IPlayerStats).points)
        .map(p =>
        {
            const player: IPlayerStats = p as IPlayerStats;

            return (
                <tr key={player.playerId}>
                    <td>{playersPageData.getPlayerName(player.playerId)}</td>
                    <td>{player.gamesPlayed}</td>
                    <td>{player.goals}</td>
                    <td>{player.assists}</td>
                    <td>{player.points}</td>
                    <td>{player.pointsPerGame}</td>
                    <td>{player.penaltyMinutes}</td>
                </tr>
            )
        });

    const goalieData = playersPageData
        .playerStats
        .filter(p => p.type === PlayerStatsType.GOALIE)
        .map(p =>
        {
            const goalie: IGoalieStats = p as IGoalieStats;

            return (
                <tr key={goalie.playerId}>
                    <td>{playersPageData.getPlayerName(goalie.playerId)}</td>
                    <td>{goalie.gamesPlayed}</td>
                    <td>{goalie.goalsOn}</td>
                    <td>{goalie.shotsOn}</td>
                    <td>{goalie.savePercent}</td>
                </tr>
            )
        });

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: "white", minHeight: "70vh" }}>
            <LoadingOverlay visible={playersPageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
            <Grid>
                <Grid.Col>
                    <h1>PLAYER STATS</h1>
                </Grid.Col>

                <Grid.Col span={3}>
                    <div className="toolbar">
                        <Select
                            label="Season"
                            data={playersPageData.getSeasonOptions()}
                            onChange={playersPageData.handleSeasonSelect}
                            value={playersPageData.currentSeason}
                        />
                    </div>
                </Grid.Col>

                <Grid.Col>
                    <Text size={30}>Players</Text>

                    <Table striped withColumnBorders>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>GP</th>
                                <th>G</th>
                                <th>A</th>
                                <th>P</th>
                                <th>PPGA</th>
                                <th>PIM</th>
                            </tr>
                        </thead>
                        <tbody>{playerData}</tbody>
                    </Table>
                </Grid.Col>

                <Grid.Col mt={50}>
                    <Text size={30}>Goalies</Text>

                    <Table striped withColumnBorders>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>GP</th>
                                <th>Goals against</th>
                                <th>Shots on</th>
                                <th>Save %</th>
                            </tr>
                        </thead>
                        <tbody>{goalieData}</tbody>
                    </Table>
                </Grid.Col>

            </Grid>
        </Container >
    )
}

export default PlayersPage;