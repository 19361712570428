import { Link } from "react-router-dom";
import IDivision from "../../types/IDivision";
import ICompetitor from "../../types/ICompetitor";
import RequestStatus from "../../types/RequestStatus";
import LoaderAnimation from "../LoaderAnimation/LoaderAnimation";
import Selector from "../Selector/Selector";
import TeamCard from "../TeamCard/TeamCard";

import styles from "./TeamListPage.module.css"
import useTeamListPage from "./useTeamListPage";

const TeamListPage = () => 
{
  const teamListData = useTeamListPage();

  let dataDiv;

  if (teamListData.requestStatus === RequestStatus.LOADING)
  {
    dataDiv = (<LoaderAnimation />);
  }
  else
  {
    dataDiv = (<>
      {teamListData.divisions.map((division: IDivision) => 
      {
        return (
          <>
            <div key={`title_${division.id}`} className={styles.divisionTitle}>
              {division.name}
            </div>
            <div key={`teams_${division.id}`} className={styles.teamList}>
              {teamListData.getFilteredCompetitors(division.id)
                .map((team: ICompetitor) => 
                {
                  return (
                    <Link to={`/Team?teamId=${team.id}&seasonId=${teamListData.currentSeason}`} style={{ textDecoration: 'none', color: "black" }} key={team.id}>
                      <TeamCard key={team.id} competitor={team} />
                    </Link>
                  )
                })}
            </div>
          </>
        )
      })}
    </>)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.displayContainer}>
        <div className={styles.heading}>
          <h1>TEAMS</h1>

          <div className="toolbar">
            <Selector
              label="Seasons"
              currentValue={teamListData.currentSeason}
              values={teamListData.seasons}
              handleChange={teamListData.handleSeasonSelect}
              allowAllSelection={false}
            />
          </div>
        </div>
        <div className={styles.dataContainer}>
          {dataDiv}
        </div>
      </div>
    </div>
  )
}

export default TeamListPage;
