import { useEffect, useState } from "react";
import IDivision from "../../types/IDivision";
import IPool from "../../types/IPool";
import ISeason from "../../types/ISeason";
import IStanding from "../../types/IStanding";
import RequestStatus from "../../types/RequestStatus";
import useCompetitors from "../../hooks/useCompetitors";
import useDivisions from "../../hooks/useDivisions";
import useSeasons from "../../hooks/useSeasons";
import { IDivisionStanding } from "../../types/IDivisionStanding";
import useStandings from "../../hooks/useStandings";
import usePools from "../../hooks/usePools";
import ICompetitor from "../../types/ICompetitor";

export interface IUseStandingPage
{
    getCompetitor(competitorId: string): ICompetitor,
    getCompetitorText(competitorId: string): string,
    getDivisionText(divisionId: string): string,
    getFilteredDivisions(): IDivision[],
    getPoolText(poolId: string): string,
    setSeasonFilter(seasonId: string): void,
    setDivisionFilter(divisionId: string): void,
    standings: IDivisionStanding | undefined,
    seasons: ISeason[],
    currentSeason: string,
    currentDivision: string,
    requestStatus: RequestStatus,
    handleSeasonSelect(event: React.ChangeEvent<HTMLInputElement>): void
    handleDivisionSelect(event: React.ChangeEvent<HTMLInputElement>): void,
}

export interface IStandingGroup
{
    pool: IPool | null,
    standings: IStanding[]
}

function useStandingPage(): IUseStandingPage
{
    const divisionData = useDivisions();
    const competitorData = useCompetitors();
    const seasonData = useSeasons();
    const standingData = useStandings();
    const poolData = usePools();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
    const [currentSeason, setCurrentSeason] = useState<string>("");
    const [currentDivision, setCurrentDivision] = useState<string>("");

    //#region Set filters

    const setSeasonFilter = (seasonId: string): void =>
    {
        setCurrentSeason(seasonId);
        setCurrentDivision("");
    }

    const setDivisionFilter = (divisionId: string): void =>
    {
        setCurrentDivision(divisionId);
    }

    //#endregion

    //#region Handlers

    const handleSeasonSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setSeasonFilter(event?.target.value);
    };

    const handleDivisionSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setDivisionFilter(event?.target.value);
    };

    //#endregion

    //#region Effects

    useEffect(() =>
    {
        async function updateData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                standingData.refreshStandings(currentSeason, currentDivision);

                setRequestStatus(RequestStatus.SUCCESS);
            }
            catch
            {
                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        updateData();
    }, [currentDivision]);

    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                await competitorData.refreshCompetitors(currentSeason);
                await poolData.refreshPools(currentSeason);

                const allDivisions = await divisionData.refreshDivisions(currentSeason);

                if (allDivisions.length > 0)
                {
                    setCurrentDivision(allDivisions[0].id);
                }
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, [currentSeason]);


    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                const availableSeasons = await seasonData.refreshSeasons();

                if (seasonData.defaultSeason)
                {
                    setCurrentSeason(seasonData.defaultSeason);

                    return;
                }

                setCurrentSeason(availableSeasons[0].id);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, [])

    //#endregion

    return {
        getCompetitor: (competitorId: string) => competitorData.getCompetitor(competitorId),
        getCompetitorText: (competitorId: string) => competitorData.getCompetitorText(competitorId),
        getDivisionText: (divisionId: string) => divisionData.getDivisionText(divisionId),
        getFilteredDivisions: () => divisionData.getFilteredDivisions(currentSeason),
        getPoolText: (poolId: string) => poolData.getPoolText(poolId),
        setSeasonFilter,
        setDivisionFilter,
        standings: standingData.standings,
        seasons: seasonData.seasons,
        currentSeason,
        currentDivision,
        requestStatus,
        handleSeasonSelect,
        handleDivisionSelect,
    }
}

export default useStandingPage;