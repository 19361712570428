import { useContext, useState } from "react";
import { IPlayerStats } from "../types/IPlayerStats";
import { playerStatsService } from "../services/PlayerStatsService";
import { DataContext } from "../contexts/DataContext";

export interface UsePlayerStatsData
{
  playerStats: IPlayerStats[],
  refreshPlayerStats(seasonId: string): Promise<IPlayerStats[]>
}

const usePlayerStats = (): UsePlayerStatsData =>
{
  const data = useContext(DataContext);

  const [playerStats, setPlayerStats] = useState<IPlayerStats[]>([]);

  const refreshPlayerStats = async (seasonId: string) =>
  {
    const league = await data.league.refreshLeague();

    if (!seasonId)
    {
      return [];
    }

    const playerStatsData = await playerStatsService.getPlayerStats(league.id, seasonId, true);

    setPlayerStats(playerStatsData);

    return playerStatsData;
  }

  return {
    playerStats,
    refreshPlayerStats
  }
}

export default usePlayerStats;