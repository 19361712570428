import ICompetitor from "../types/ICompetitor";
import { CompetitorType } from "../types/ICompetitorReference";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

export interface GetCompetitorDetailsProps 
{
  leagueId: string,
  seasonId: string,
  reference: string,
  type: CompetitorType,
  divisionId?: string,
  poolId?: string
}

class CompetitorService
{
  private competitors: ICompetitor[] = []

  getCompetitors = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<ICompetitor[]> =>
  {
    if (this.competitors.length === 0 || forceRefresh)
    {
      if (!leagueId || !seasonId)
      {
        return [];
      }

      const apiPath = `league/${leagueId}/season/${seasonId}/competitor`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as ICompetitor[]

      this.competitors = records;
    }

    return this.competitors;
  }

  getCompetitor = async (leagueId: string, seasonId: string, competitorId: string): Promise<ICompetitor | null> =>
  {
    const targetCompetitor = (await this.getCompetitors(leagueId, seasonId))
      .find(g => g.id === competitorId)

    return targetCompetitor ?? null;
  }

  getCompetitorPlayers = async (leagueId: string, seasonId: string, competitorId: string): Promise<string[]> =>
  {
    const competitor = await this.getCompetitor(leagueId, seasonId, competitorId);

    return competitor?.playerIds ?? [];
  }

  getCompetitorGoalies = async (leagueId: string, seasonId: string, competitorId: string): Promise<string[]> =>
  {
    const competitor = await this.getCompetitor(leagueId, seasonId, competitorId);

    return competitor?.goalieIds ?? [];
  }
}

export const competitorService = new CompetitorService();