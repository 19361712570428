const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const days = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
]

export default function getTimeString(date: string): string 
{
  const dateData: Date = new Date(date);
  const month: string = months[dateData.getMonth()];
  const weekDay: string = days[dateData.getDay()];
  const day: string = dateData.getDate().toString()
  const hour: string = (dateData.getHours() > 12 ? dateData.getHours() - 12 : dateData.getHours()).toString();
  const minutes: string = dateData.getMinutes().toLocaleString("en-US", { minimumIntegerDigits: 2 }).toString();
  const timeSuffix: string = dateData.getHours() > 12 ? "pm" : "am";
  const time = `${hour}:${minutes}`

  return `${weekDay}, ${month} ${day}, ${time} ${timeSuffix}`;
}
