export enum PlayerStatsType
{
  PLAYER = "PLAYER",
  GOALIE = "GOALIE"
}

interface IBaseStats
{
  playerId: string,
  type: PlayerStatsType,
  gamesPlayed: number
}

export interface IPlayerStats extends IBaseStats
{
  type: PlayerStatsType.PLAYER
  goals: number,
  assists: number,
  points: number,
  pointsPerGame: number,
  penaltyMinutes: number
}

export interface IGoalieStats extends IBaseStats
{
  type: PlayerStatsType.GOALIE,
  shotsOn: number,
  goalsOn: number,
  savePercent: number
}