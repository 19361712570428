import { useEffect, useState } from "react";
import IGame from "../../types/IGame";
import ICompetitor from "../../types/ICompetitor";
import useCompetitors from "../../hooks/useCompetitors";
import useDivisions from "../../hooks/useDivisions";
import useGames from "../../hooks/useGames";
import { RequestStatus } from "../../types/RequestStatus";
import { ICompetitorReference } from "../../types/ICompetitorReference";
import useLocations from "../../hooks/useLocations";
import usePools from "../../hooks/usePools";

export interface IUseTeamPageData
{
    competitor: ICompetitor,
    divisionName: string
    games: IGame[],
    getCompetitorByReference(competitorReference: ICompetitorReference): ICompetitor,
    getCompetitorText(competitor: ICompetitorReference): string,
    getDivisionText(divisionId: string): string,
    getLocationText(locationId: string): string,
    getPoolText(poolId: string): string,
    requestStatus: RequestStatus,
}

export interface IUseTeamPageProps
{
    competitorId: string,
    seasonId: string
}

function useTeamPage(props: IUseTeamPageProps): IUseTeamPageData
{
    const competitorData = useCompetitors();
    const divisionData = useDivisions();
    const gameData = useGames();
    const locationData = useLocations();
    const poolData = usePools();

    const [competitor, setCompetitor] = useState<ICompetitor>();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);

    //#region Effects

    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);


                const allCompetitors = await competitorData.refreshCompetitors(props.seasonId);

                await gameData.refreshGames(props.seasonId);
                await locationData.refreshLocations();
                await divisionData.refreshDivisions(props.seasonId);
                await poolData.refreshPools(props.seasonId);

                setCompetitor(
                    allCompetitors.find(c => c.id === props.competitorId)
                )

                setRequestStatus(RequestStatus.SUCCESS);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, []);

    //#endregion

    return {
        competitor: competitor ?? competitorData.getCompetitor(),
        divisionName: divisionData.getDivisionText(competitorData.getCompetitor(props.competitorId).divisionId),
        getCompetitorByReference: competitorData.getCompetitorByReference,
        getCompetitorText: competitorData.getCompetitorReferenceText,
        getDivisionText: divisionData.getDivisionText,
        getLocationText: locationData.getLocationText,
        getPoolText: poolData.getPoolText,
        games: gameData.getFilteredGames(props.seasonId, undefined, props.competitorId),
        requestStatus
    }
}

export default useTeamPage;