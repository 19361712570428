import { useEffect, useState } from "react";
import ILeague from "../types/ILeague";
import { leagueService } from "../services/LeagueService";

export interface ILeagueContext
{
  league: ILeague | null
  refreshLeague(): Promise<ILeague>
}

function useLeague(): ILeagueContext 
{
  const [league, setLeague] = useState<ILeague | null>(null);

  const refreshLeague = async (forceRefresh?: boolean): Promise<ILeague> => 
  {
    if (!league || forceRefresh)
    {
      const leagueData: ILeague = await leagueService.getLeague();
      setLeague(leagueData);

      return leagueData
    }

    return league;
  }

  useEffect(() => 
  {
    refreshLeague();
  }, []);


  return {
    league,
    refreshLeague
  }
}

export default useLeague;