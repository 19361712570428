import { useContext, useEffect, useState } from "react";
import { DataContext, IDataContext } from "../../contexts/DataContext";
import IDivision from "../../types/IDivision";
import IGameList from "../../types/IGame";
import ISeason from "../../types/ISeason";
import ICompetitor from "../../types/ICompetitor";
import RequestStatus from "../../types/RequestStatus";
import { ICompetitorIdReference, ICompetitorReference } from "../../types/ICompetitorReference";
import useLocations from "../../hooks/useLocations";
import usePools from "../../hooks/usePools";
import useDivisions from "../../hooks/useDivisions";
import useCompetitors from "../../hooks/useCompetitors";
import useSeasons from "../../hooks/useSeasons";
import useGames from "../../hooks/useGames";

export interface IUseGamePage
{
    setSeasonFilter(seasonId: string): void,
    setDivisionFilter(divisionId: string): void
    setTeamFilter(teamIds: string): void,
    getCompetitorByReference(competitorReference: ICompetitorIdReference): ICompetitor,
    getCompetitorText(competitor: ICompetitorReference): string,
    getDivisionText(divisionId: string): string,
    getFilteredDivisions(): IDivision[],
    getFilteredTeams(): ICompetitor[],
    getGames(): IGameList[],
    getLocationText(locationId: string): string,
    getPoolText(poolId: string): string,
    seasons: ISeason[],
    currentSeason: string | undefined,
    currentDivision: string | undefined,
    currentCompetitor: string | undefined,
    requestStatus: RequestStatus,
    handleSeasonSelect(event: React.ChangeEvent<HTMLInputElement>): void
    handleDivisionSelect(event: React.ChangeEvent<HTMLInputElement>): void,
    handleTeamSelect(event: React.ChangeEvent<HTMLInputElement>): void
}

function useGamePage(): IUseGamePage
{
    const data: IDataContext = useContext(DataContext);

    const locationData = useLocations();
    const poolData = usePools();
    const divisionData = useDivisions();
    const competitorData = useCompetitors();
    const seasonData = useSeasons();
    const gameData = useGames();

    const [currentSeason, setCurrentSeason] = useState<string>("");
    const [currentDivision, setCurrentDivision] = useState<string>("");
    const [currentCompetitor, setCurrentCompetitor] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);

    //#region Set filters

    const setTeamFilter = (teamId: string): void => setCurrentCompetitor(teamId);

    const setSeasonFilter = (seasonId: string): void =>
    {
        setCurrentSeason(seasonId);
        setDivisionFilter("");
    }

    const setDivisionFilter = (divisionId: string): void =>
    {
        setCurrentDivision(divisionId);
        setCurrentCompetitor("");
    }

    //#endregion

    //#region Handlers

    const handleSeasonSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setSeasonFilter(event?.target.value);
    };

    const handleDivisionSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setDivisionFilter(event?.target.value);
    };

    const handleTeamSelect = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        setTeamFilter(event?.target.value);
    };

    //#endregion

    //#region Effects

    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                const league = await data.league.refreshLeague();

                let season = currentSeason;

                if (!season)
                {
                    season = league.defaultSeasonId ?? "";

                    setCurrentSeason(season);
                }

                await seasonData.refreshSeasons();
                await locationData.refreshLocations();
                await poolData.refreshPools(currentSeason);
                await divisionData.refreshDivisions(currentSeason);
                await competitorData.refreshCompetitors(currentSeason);
                await gameData.refreshGames(currentSeason);

                setRequestStatus(RequestStatus.SUCCESS);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, [currentSeason]);

    //#endregion

    return {
        setSeasonFilter,
        setDivisionFilter,
        setTeamFilter,
        getCompetitorByReference: competitorData.getCompetitorByReference,
        getCompetitorText: competitorData.getCompetitorReferenceText,
        getDivisionText: divisionData.getDivisionText,
        getFilteredDivisions: () => divisionData.getFilteredDivisions(currentSeason),
        getFilteredTeams: () => competitorData.getFilteredCompetitors(currentDivision),
        getGames: () => gameData.getFilteredGames(currentSeason, currentDivision, currentCompetitor),
        getLocationText: locationData.getLocationText,
        getPoolText: poolData.getPoolText,
        seasons: seasonData.seasons,
        currentSeason,
        currentDivision,
        currentCompetitor,
        requestStatus,
        handleSeasonSelect,
        handleDivisionSelect,
        handleTeamSelect
    }
}

export default useGamePage;