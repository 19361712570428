import { IPlayerStats } from "../types/IPlayerStats";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class PlayerStatsService
{
  private playerStats: IPlayerStats[] = [];

  getPlayerStats = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<IPlayerStats[]> =>
  {
    if (this.playerStats.length === 0 || forceRefresh)
    {
      let apiPath: string;

      if (seasonId == "All Seasons")
      {
        apiPath = `league/${leagueId}/playerstats`
      }
      else
      {
        apiPath = `league/${leagueId}/season/${seasonId}/playerstats`
      }

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IPlayerStats[]

      this.playerStats = records;
    }

    return this.playerStats;
  }
}

export const playerStatsService = new PlayerStatsService();