import { CircularProgress, Grid } from "@mui/material";
import React from "react";

function LoaderAnimation() 
{
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '5vh' }}
    >
      <CircularProgress />
    </Grid>
  )
}

export default LoaderAnimation;
