import { useContext, useState } from "react";
import { gameService } from "../services/GameService";
import IGame from "../types/IGame";
import { ICompetitorIdReference } from "../types/ICompetitorReference";
import { DataContext } from "../contexts/DataContext";

export interface UseGamesData
{
  games: IGame[],
  getFilteredGames(seasonId?: string, divisionId?: string, competitorId?: string): IGame[],
  refreshGames(seasonId: string): Promise<IGame[]>
}

const useGames = (): UseGamesData =>
{
  const data = useContext(DataContext);

  const [games, setGames] = useState<IGame[]>([]);

  const refreshGames = async (seasonId: string): Promise<IGame[]> =>
  {
    if (!seasonId)
    {
      return [];
    }

    const league = await data.league.refreshLeague();

    const gameData = await gameService.getGames(league.id, seasonId, true);

    setGames(gameData);

    return gameData;
  }

  const getFilteredGames = (seasonId?: string, divisionId?: string, competitorId?: string): IGame[] =>
  {
    if (!games) return [];

    return games
      .filter((game => seasonId ? game.seasonId === seasonId : true))
      .filter((game => divisionId ? game.divisionId === divisionId : true))
      .filter((game => competitorId ?
        (game.competitorOne as ICompetitorIdReference).id === competitorId
        || (game.competitorTwo as ICompetitorIdReference).id === competitorId : true))
  }

  return {
    games,
    getFilteredGames,
    refreshGames
  }
}

export default useGames;