import axios from "axios";
import { IScorebenchApiParameter, IScorebenchApiResponse } from "../types/ScorebenchApi";

const scorebenchApiEndpoint: string = process.env.REACT_APP_API_ENDPOINT || "";

function buildParameterString(parameters: IScorebenchApiParameter[] = []): string 
{
  const paramString: string[] = [];

  parameters.forEach((param) => paramString.push(`${param.name}=${param.value}`));

  return paramString.join("&");
}

export async function getApiData(apiPath: string, parameters?: IScorebenchApiParameter[]): Promise<IScorebenchApiResponse> 
{
  const parameterString: string = buildParameterString(parameters);
  let finalUrl = `${scorebenchApiEndpoint}/${apiPath}`;

  if (parameterString) 
  {
    finalUrl = `${finalUrl}?${parameterString}`;
  }

  const result = await axios.get(finalUrl);

  return result.data as IScorebenchApiResponse;
}
