import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Selector from "../Selector/Selector";
import StandingTable from "../StandingTable/StandingTable";
import React from "react";
import useStandingPage, { IUseStandingPage } from "./useStandingPage";

const StandingPage = () => 
{
  const standingData: IUseStandingPage = useStandingPage();

  let standingsTables;

  if (standingData.standings && !standingData.standings.poolStandings)
  {
    standingsTables = (
      <>
        <StandingTable
          key={standingData.standings.divisionId}
          getCompetitor={standingData.getCompetitor}
          getCompetitorText={standingData.getCompetitorText}
          standings={standingData.standings.standings}
          requestStatus={standingData.requestStatus}
        />
      </>
    )
  }
  else if (standingData.standings && standingData.standings.poolStandings)
  {
    standingsTables = (
      <>
        {standingData.standings.poolStandings.map((standingGroup) => (
          <>
            <h3 key={`p_${standingGroup.poolId}`}>{standingData.getPoolText(standingGroup.poolId)}</h3>
            <StandingTable
              key={standingGroup.poolId}
              getCompetitor={standingData.getCompetitor}
              getCompetitorText={standingData.getCompetitorText}
              standings={standingGroup.standings}
              requestStatus={standingData.requestStatus}
            />
          </>
        ))
        };
      </>
    )
  }

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "white", minHeight: "70vh" }}>
      <Grid container justifyContent="center" direction="column" alignItems="center">
        <Grid item width="100%">
          <h1>STANDINGS</h1>

          <div className="toolbar">

            <Selector
              label="Seasons"
              currentValue={standingData.currentSeason}
              values={standingData.seasons}
              handleChange={standingData.handleSeasonSelect}
            />

            <Selector
              label="Divisions"
              currentValue={standingData.currentDivision}
              values={standingData.getFilteredDivisions()}
              handleChange={standingData.handleDivisionSelect}
            />

          </div>
        </Grid>

        <Grid item paddingTop="1em" paddingBottom="1em" fontSize={14} width="100%">
          {standingsTables}
        </Grid>
      </Grid>
    </Container >
  )
}

export default StandingPage;
