import { Divider, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useSearchParams } from "react-router-dom";

import styles from "./TeamPage.module.css"
import useTeamPage from "./useTeamPage";
import GameTable from "../GameTable/GameTable";
import { RequestStatus } from "../../types/RequestStatus";
import TeamLogo, { LOGOSIZE } from "../TeamLogo/TeamLogo";

function TeamPage() 
{
  const [queryParams] = useSearchParams();
  const competitorId = queryParams.get("teamId") || "";
  const seasonId = queryParams.get("seasonId") || "";

  const competitorData = useTeamPage({
    competitorId,
    seasonId
  });

  return (

    <Container maxWidth="xl" sx={{ backgroundColor: "white", minHeight: "70vh" }}>
      <Grid container justifyContent="center" direction="column" alignItems="center">
        <Grid item paddingTop="2em">
          <TeamLogo
            competitor={competitorData.competitor}
            size={LOGOSIZE.LARGE}
          />
        </Grid>
        <Grid item>
          <p className={styles.teamTitle}>{competitorData.competitor?.name}</p>
        </Grid>
        <Grid item>
          <p className={styles.divisionTitle}>{competitorData.divisionName}</p>
        </Grid>
        <Grid item width="100%">
          <Divider />
        </Grid>
        <Grid item paddingTop="2em" width="100%">
          <h2>Games</h2>
          <GameTable
            getCompetitorByReference={competitorData.getCompetitorByReference}
            getCompetitorText={competitorData.getCompetitorText}
            getDivisionText={competitorData.getDivisionText}
            getLocationText={competitorData.getLocationText}
            getPoolText={competitorData.getPoolText}
            loading={competitorData.requestStatus === RequestStatus.LOADING}
            games={competitorData.games}
          />
        </Grid>
      </Grid>
    </Container >
  )
}

export default TeamPage;
