import IPlayer from "../types/IPlayer";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class PlayerService
{
  private players: IPlayer[] = [];

  getPlayers = async (leagueId: string, forceRefresh?: boolean): Promise<IPlayer[]> =>
  {
    if (this.players.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/player`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IPlayer[]

      this.players = records;
    }

    return this.players;
  }

  getPlayer = async (leagueId: string, playerId: string): Promise<IPlayer | null> =>
  {
    const targetPlayer = (await this.getPlayers(leagueId))
      .find(l => l.id === playerId);

    return targetPlayer ?? null;
  }
}

export const playerService = new PlayerService();