import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Grid, Typography } from '@mui/material';

const Footer = () => 
{
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container maxWidth="xl" justifyContent="center" direction="column" alignItems="stretch">
        <Grid item paddingBottom="2em" width="100%">
          <Toolbar sx={{ backgroundColor: "black" }}>

            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
              <Typography variant="h6" sx={{ my: 2, color: 'white', display: 'block' }}>Scorebench</Typography>
            </Box>
          </Toolbar>
        </Grid>
      </Grid>
    </Container>
  )
};

export default Footer;
