import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import GameTable from "../GameTable/GameTable";
import Selector from "../Selector/Selector";
import useGamePage, { IUseGamePage } from "./useGamePage";
import RequestStatus from "../../types/RequestStatus";

export enum GamePageType
{
  SCHEDULE = "SCHEDULE",
  RESULTS = "RESULTS"
}

const GamePage = () =>
{
  const gamePageData: IUseGamePage = useGamePage();

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "white", minHeight: "70vh" }}>
      <Grid container justifyContent="center" direction="column" alignItems="center">
        <Grid item width="100%">
          <h1>GAMES</h1>

          <div className="toolbar">
            <Selector
              label="Seasons"
              currentValue={gamePageData.currentSeason}
              values={gamePageData.seasons}
              handleChange={gamePageData.handleSeasonSelect}
              allowAllSelection={false}
            />

            <Selector
              label="Divisions"
              currentValue={gamePageData.currentDivision}
              values={gamePageData.getFilteredDivisions()}
              handleChange={gamePageData.handleDivisionSelect}
              allowAllSelection={true}
            />

            <Selector
              label="Teams"
              currentValue={gamePageData.currentCompetitor}
              values={gamePageData.getFilteredTeams()}
              handleChange={gamePageData.handleTeamSelect}
              allowAllSelection={true}
            />
          </div>
        </Grid>
        <Grid item paddingTop="1em" sx={{ width: { "xs": "100%", "sm": "100%", "md": "100%", "lg": "100%", "xl": "100%" } }}>
          <GameTable
            getCompetitorByReference={gamePageData.getCompetitorByReference}
            getCompetitorText={gamePageData.getCompetitorText}
            getDivisionText={gamePageData.getDivisionText}
            getLocationText={gamePageData.getLocationText}
            getPoolText={gamePageData.getPoolText}
            loading={gamePageData.requestStatus === RequestStatus.LOADING}
            games={gamePageData.getGames()}
          />
        </Grid>

      </Grid>
    </Container >
  )
}

export default GamePage;
