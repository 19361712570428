import React from "react";
import stringToColor from "../../utils/stringToColor";

import styles from "./TeamLogo.module.css"
import ICompetitor from "../../types/ICompetitor";

export enum LOGOSIZE
{
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE"
}

const logoSizeMap = {
  SMALL: styles.logoSmall,
  MEDIUM: styles.logoMedium,
  LARGE: styles.logoLarge
}

const avatarSizeMap = {
  SMALL: styles.avatarSmall,
  MEDIUM: styles.avatarMedium,
  LARGE: styles.avatarLarge
}
interface ICompetitorLogoProps
{
  competitor: ICompetitor,
  size?: LOGOSIZE
}

const TeamLogo = (props: ICompetitorLogoProps) =>
{
  const size: LOGOSIZE = props.size ? props.size : LOGOSIZE.SMALL;
  const logoStyle: string = logoSizeMap[size]
  const avatarStyle: string = avatarSizeMap[size]

  if (props.competitor.logoId)
  {
    const logoUrl = `${props.competitor.logoId}.jpeg`

    return (
      <>
        <img className={logoStyle} src={logoUrl} onError={(event) => event.currentTarget.style.display = 'none'} />
      </>
    )
  }

  const backgroundColor: string = stringToColor(props.competitor.name)

  return (
    <div className={avatarStyle} style={{ backgroundColor: backgroundColor }}>
      <p>{props.competitor.name.substring(0, 1)}</p>
    </div>
  )
};

export default TeamLogo;