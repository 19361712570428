import React, { createContext } from "react";
import useLeague, { ILeagueContext } from "../hooks/useLeague";

export interface IDataContext
{
  league: ILeagueContext
}

const DataContext = createContext<IDataContext>(null!);

type DataProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

function DataProvider({ children }: DataProviderProps) 
{
  const data: IDataContext = {
    league: useLeague()
  }

  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, DataProvider };
