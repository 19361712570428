import IGame from "../types/IGame";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class GameService
{
  private games: IGame[] = [];

  getGames = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<IGame[]> =>
  {
    if (this.games.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season/${seasonId}/game`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as IGame[]

      this.games = records;
    }

    return this.games;
  }

  getGame = async (leagueId: string, seasonId: string, gameId: string): Promise<IGame> =>
  {
    const apiPath = `league/${leagueId}/season/${seasonId}/game/${gameId}`

    const data: IScorebenchApiResponse = await getApiData(apiPath);
    const records = data.data as IGame[]

    return records[0];
  }
}

export const gameService = new GameService();