import { useContext, useState } from "react";
import { competitorService } from "../services/CompetitorService";
import ICompetitor from "../types/ICompetitor";
import { CompetitorType, ICompetitorIdReference, ICompetitorReference, ICompetitorTextReference } from "../types/ICompetitorReference";
import { DataContext } from "../contexts/DataContext";

export interface UseCompetitorListData
{
  competitors: ICompetitor[],
  refreshCompetitors(seasonId: string): Promise<ICompetitor[]>
  getCompetitor(competitorId?: string): ICompetitor
  getCompetitorText(competitorId: string): string,
  getCompetitorByReference(competitorReference: ICompetitorIdReference): ICompetitor,
  getCompetitorReferenceText(competitorReference: ICompetitorReference): string,
  getFilteredCompetitors(divisionId: string): ICompetitor[]
}

const useCompetitors = (): UseCompetitorListData =>
{
  const data = useContext(DataContext);

  const [competitors, setCompetitors] = useState<ICompetitor[]>([]);

  const getCompetitor = (competitorId?: string): ICompetitor =>
  {
    const targetCompetitor = competitors.find(c => c.id === competitorId)

    if (!targetCompetitor)
    {
      return {
        id: "",
        divisionId: "",
        leagueId: "",
        logoId: "",
        name: "Unknown",
        poolId: "",
        seasonId: ""
      }
    }

    return targetCompetitor;
  };

  const getCompetitorText = (competitorId: string) => 
  {
    if (!competitorId) return "";

    return getCompetitor(competitorId)?.name ?? "Unknown"
  }

  const getCompetitorByReference = (competitorReference: ICompetitorIdReference): ICompetitor =>
  {
    return getCompetitor(competitorReference.id);
  }

  const getCompetitorReferenceText = (competitorReference: ICompetitorReference): string =>
  {
    if (competitorReference.type === CompetitorType.ID)
    {
      const competitorId = (competitorReference as ICompetitorIdReference).id;

      return competitors.find(c => c.id === competitorId)?.name ?? "Unknown";
    }

    return (competitorReference as ICompetitorTextReference).text;
  }

  const refreshCompetitors = async (seasonId: string): Promise<ICompetitor[]> =>
  {
    const league = await data.league.refreshLeague();

    const competitorData = await competitorService.getCompetitors(league.id, seasonId, true);

    setCompetitors(competitorData);

    return competitorData;
  }

  const getFilteredCompetitors = (divisionId: string): ICompetitor[] =>
  {
    return competitors.filter((c) =>
    {
      return c.divisionId === divisionId;
    })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return {
    competitors,
    refreshCompetitors,
    getCompetitor,
    getCompetitorText,
    getCompetitorByReference,
    getCompetitorReferenceText,
    getFilteredCompetitors
  }
}

export default useCompetitors;