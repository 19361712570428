import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

interface IPageLink
{
  name: string,
  link: string
}

const pages: IPageLink[] = [
  {
    name: "Games",
    link: "/Games"
  },
  {
    name: "Standings",
    link: "/Standings"
  },
  {
    name: "Teams",
    link: "/Teams"
  },
  {
    name: "Players",
    link: "/Players"
  }
]

const Header = () =>
{
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container maxWidth="xl" justifyContent="center" direction="column" alignItems="stretch">
        <Grid item paddingTop="2em" width="100%">
          <Toolbar sx={{ backgroundColor: "black" }}>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.link} onClick={handleCloseNavMenu}>
                    <Link to={page.link} style={{ textDecoration: 'none', color: "black" }}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ display: { xs: "none", sm: "none", "md": "flex", "lg": "flex", "xl": "flex" } }}>
              {
                pages.map((page) => (
                  <Button
                    key={page.name}
                    disableRipple={false}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    href={page.link}
                  >
                    {page.name}
                  </Button>
                ))
              }
            </Box>
          </Toolbar>
        </Grid>
      </Grid>
    </Container >
  );
};

export default Header;
