import ILocation from "../types/ILocation";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class LocationService
{
  private locations: ILocation[] = [];

  getLocations = async (leagueId: string, forceRefresh?: boolean): Promise<ILocation[]> =>
  {
    if (this.locations.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/location`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as ILocation[]

      this.locations = records;
    }

    return this.locations;
  }

  getLocation = async (leagueId: string, locationId: string): Promise<ILocation | null> =>
  {
    const targetLocation = (await this.getLocations(leagueId))
      .find(l => l.id === locationId)

    return targetLocation ?? null;
  }
}

export const locationService = new LocationService();