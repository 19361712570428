import { useContext, useEffect, useState } from "react";
import RequestStatus from "../../types/RequestStatus";
import { DataContext, IDataContext } from "../../contexts/DataContext";
import ISeason from "../../types/ISeason";
import { useNavigate } from "react-router-dom";
import { IGoalieStats, IPlayerStats } from "../../types/IPlayerStats";
import { IRecordOption } from "../../types/IRecordType";
import useSeasons from "../../hooks/useSeasons";
import usePlayers from "../../hooks/usePlayers";
import usePlayerStats from "../../hooks/usePlayerStats";

export interface UsePlayersPageData
{
    currentSeason: string,
    seasons: ISeason[],
    playerStats: (IPlayerStats | IGoalieStats)[],
    requestStatus: RequestStatus,
    handleSeasonSelect(targetSeason: string): void,
    getPlayerName(playerId: string): string,
    getSeasonOptions(): IRecordOption[]
}

export interface UsePlayersPageProps
{
    seasonId?: string
}

const usePlayersPage = (props: UsePlayersPageProps) =>
{
    const data: IDataContext = useContext(DataContext);

    const navigate = useNavigate();

    const seasonData = useSeasons();
    const playerData = usePlayers();
    const playerStatsData = usePlayerStats();

    const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
    const [currentSeason, setCurrentSeason] = useState<string>("");

    const handleSeasonSelect = (targetSeason: string) => 
    {
        setCurrentSeason(targetSeason);

        if (targetSeason === "All Seasons")
        {
            navigate(`/players`);

            return;
        }

        navigate(`/players/season/${targetSeason}`);
    };

    const getSeasonOptions = (): IRecordOption[] =>
    {
        let seasonOptions = seasonData.seasons.map(s =>
        {
            return {
                label: s.name,
                value: s.id
            }
        });

        seasonOptions = [...seasonOptions,
        {
            label: "All Seasons",
            value: "All Seasons"

        }]

        return seasonOptions;
    }

    useEffect(() =>
    {
        const fetchPlayerStats = async () =>
        {
            try
            {
                if (!currentSeason)
                {
                    return;
                }

                setRequestStatus(RequestStatus.LOADING);

                await playerStatsData.refreshPlayerStats(currentSeason);

                setRequestStatus(RequestStatus.SUCCESS);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        fetchPlayerStats()
    }, [currentSeason])

    useEffect(() =>
    {
        async function getData()
        {
            try
            {
                setRequestStatus(RequestStatus.LOADING);

                const league = await data.league.refreshLeague();

                await seasonData.refreshSeasons();
                await playerData.refreshPlayers();

                let seasonId = props.seasonId;

                if (!seasonId)
                {
                    seasonId = league.defaultSeasonId;
                }

                setCurrentSeason(seasonId);
            }
            catch (e)
            {
                console.log(e);

                setRequestStatus(RequestStatus.FAILURE);
            }
        }

        getData();
    }, []);

    return {
        currentSeason,
        seasons: seasonData.seasons,
        playerStats: playerStatsData.playerStats,
        requestStatus,
        handleSeasonSelect,
        getPlayerName: (playerId: string) => playerData.getPlayerText(playerId),
        getSeasonOptions
    }
}

export default usePlayersPage;